:root {
  // safari の場合16px以下に設定すると自動的にズームされてしまうため
  // 16px以下に設定したい場合はtransformなどで対応する
  --default-font-size: 1.6rem;

  // カリキュラムのline-height
  --curriculum-default-line-height: 2.2;

  // Helvetica Neue = macOs,ios(欧文)
  // Hiragino Kaku Gothic ProN = macOs,ios(和文)
  // Arial = windows(欧文)
  // Meiryo = windows(和文)
  // androidその他 = sans-serif
  --font-family: 'Helvetica Neue', 'Hiragino Kaku Gothic ProN', 'Arial', 'Meiryo',
    sans-serif;
}

.app-loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--neutral-white);

  &.-transparent {
    background-color: var(--filter-white);
  }

  &.-fullwidth {
    left: 0;
  }

  > .loader {
    text-align: center;
  }

  > .loader > .sk-chase {
    margin-bottom: 24px;
  }

  > .loader > .text {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.guest-layout-container {
  min-height: 100vh;
  background: #F1EDE5;

  > .body {
    max-width: 600px;
    margin: 0 auto;
  }

  > .body > .head {
    padding: 64px 24px 0;
  }

  > .body > .head > .image {
    width: 180px;
  }
}

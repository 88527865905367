.apply-agreement {
  display: grid;
  justify-content: center;
  row-gap: 32px;
  margin-top: 32px;
  text-align: center;

  > .agreement {
    font-size: 1.6rem;
    color: var(--black);
  }
}

.app-eye {
  position: absolute;
  top: 29px;
  right: 6px;
  padding: 20px;
  margin-left: 16px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  transition: all 0.3s;
  cursor: pointer;
}

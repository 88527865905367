.privacy-policy {
  padding: 42px 24px 69px;

  > .title > .h1 {
    padding-bottom: 32px;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1;
  }

  > .title > .notice {
    padding-bottom: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  > .body {
    margin: 24px 0;
  }
}

.app-password-input {
  position: relative;

  > .label {
    display: block;
    margin-bottom: 12px;
    font-size: 1.6rem;
    line-height: 1;
  }

  > .input {
    width: 100%;
    padding: 12px 8px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
  }

  > .notice {
    margin-top: 12px;
    font-size: 1.2rem;
    line-height: 1;
    color: var(--black);
  }

  > .error {
    display: block;
    margin-top: 12px;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
    color: var(--text-error);
  }
}

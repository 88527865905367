.list-text {
  > .h2 {
    margin-bottom: 16px;
    font-size: 2rem;
    font-weight: 400;
    color: var(--black);
    line-height: 1.5;
  }

  > .body {
    padding-left: 22px;
    margin-bottom: 16px;
  }
}

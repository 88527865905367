.app-button {
  min-width: 192px;
  min-height: 52px;
  margin: 0 auto;
  font-size: 2.0rem;
  font-weight: 400;
  line-height: 1;
  background: var(--black);
  border: 1px solid var(--black);
  border-radius: 20px;
  color: var(--neutral-white);
  cursor: pointer;
  transition: 0.3s;

  &.-disabled {
    color: var(--black);
    background-color: var(--gray);
    border: 1px solid var(--gray);
    cursor: not-allowed;
  }
}

:root {
  --black: #000;
  --dark-gray: #d5d5d5;
  --gray: #BDBDBD;
  --neutral-black: #1d1d1f;
  --neutral-white: #fff;
  --neutral-gray: #c2c2c2;
  --yellow: #f2c94c;
  --light: #fae9b7;

  // Text
  --text-error: #FF2200;

  // Filter
  --filter-white: rgba(255, 255, 255, 0.8);
}

.backend-errors {
  margin-bottom: 16px;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: var(--text-error);

  > .error {
    list-style-type: none;
  }
}
